<template>
  <TTView>
    <VRow>
      <VCol>
        <h1>Топики</h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <h3>Редактировать топик</h3>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <VForm
          ref="form"
          v-model="isValid"
          lazy-validation
        >
          <VRow>
            <VCol cols="12">
              <TTTextField
                v-model="topic.name"
                label="Название"
                :rules="[rules.required]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <TTTextField
                v-model="topic.key"
                label="Ключ топика"
                :rules="[rules.required]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <TTTextarea
                v-model="topic.description"
                label="Описание"
                :rules="[rules.required]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <ContentPicker
                v-model="topic.contentIds"
                :content-items="contentItems.values"
                :loading="contentItems.loading"
                :rules="[rules.required]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <TTBtn
                :disabled="!isValid"
                @click="submit"
              >
                Сохранить
              </TTBtn>
            </VCol>
          </VRow>
        </VForm>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { required } from '@front.backoffice/common';
import ContentPicker from '../../components/learning/ContentPicker.vue';

export default {
  name: 'EditTopic',
  components: {
    ContentPicker,
  },
  data() {
    return {
      topic: {
        id: undefined,
        key: '',
        name: '',
        description: '',
        contentIds: [],
      },
      isValid: false,
      loading: false,
      contentItems: {
        values: [],
        query: '',
        loading: false,
      },
    };
  },
  computed: {
    rules() {
      return {
        required: required(),
      };
    },
  },
  async created() {
    this.topic = await this.fetchTopic(this.$route.params.topicId);
    // TODO: Заменить на поиск с параметром когда сделают
    this.contentItems.values = await this.fetchContentItemsAll();
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        await this.updateTopic(this.topic);
      }
    },
    async fetchTopic(id) {
      try {
        this.loading = true;
        const { topic = null } = await this.$di.api.TtCourse.getTopic({ id });
        return topic;
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return null;
      } finally {
        this.loading = false;
      }
    },
    async updateTopic(topic) {
      try {
        this.loading = true;
        const id = await this.$di.api.TtCourse.updateTopic({
          id: topic.id,
          topic,
        });
        this.$di.notify.snackSuccess('Топик отредактирован');
        return id;
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return null;
      } finally {
        this.loading = false;
      }
    },
    // Ручка возвращает все записи, независимо от аргумента. Потом сделают чтобы искала.
    async fetchContentItemsByText(textForSearch = '') {
      try {
        this.contentItems.loading = true;
        const { contentItems = [] } = await this.$di.api.TtCourse.searchContentItems({ textForSearch });
        return contentItems;
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return [];
      } finally {
        this.contentItems.loading = false;
      }
    },
    // TODO: Убрать метод и зависимости, когда сделают нормальную ручку поиска
    async fetchContentItemsAll() {
      try {
        this.contentItems.loading = true;
        return await this.fetchContentItemsPagedList();
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return [];
      } finally {
        this.contentItems.loading = false;
      }
    },
    async fetchContentItemsPagedList(pageSize = 30) {
      const firstPage = this.fetchContentItemsPage(1, pageSize);
      const { pagination: { total = 0 } } = await firstPage;
      const pageCount = Math.ceil(total / pageSize);

      const promises = [firstPage];
      for (let pageNumber = 2; promises.length < pageCount; pageNumber += 1) {
        promises.push(this.fetchContentItemsPage(pageNumber, pageSize));
      }

      return Promise.all(promises).then((set) => set.reduce((accum, { contentItems }) => {
        accum.push(...contentItems);
        return accum;
      }, []));
    },
    async fetchContentItemsPage(page = 1, limit = 30) {
      try {
        return await this.$di.api.MultiContent.sandboxIndex({ page, limit });
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return null;
      }
    },
  },
};
</script>
